<div class="container">
  <div class="row">
    <div class="col-md-3">
      <ng-container *ngVar="(parent$ | async) as parent">
      <ng-container *ngIf="parent?.payload as parentContext">
          <div class="d-flex flex-row border-bottom mb-4 pb-4">
  
              <header class="comcol-header mr-auto">
          <!-- Parent Name -->
          <ds-comcol-page-header [name]="dsoNameService.getName(parentContext)">
          </ds-comcol-page-header>
        <!-- Collection logo -->
          <ds-comcol-page-logo *ngIf="logo$"
                               [logo]="(logo$ | async)?.payload"
                               [alternateText]="'Community or Collection Logo'">
          </ds-comcol-page-logo>
          <!-- Handle -->
          <!-- <ds-themed-comcol-page-handle
              [content]="parentContext.handle"
              [title]="parentContext.type+'.page.handle'" >
          </ds-themed-comcol-page-handle> -->
          <!-- Introductory text -->
          <ds-comcol-page-content [content]="parentContext.introductoryText" [hasInnerHtml]="true">
          </ds-comcol-page-content>
          <!-- News -->
          <ds-comcol-page-content [content]="parentContext.sidebarText" [hasInnerHtml]="true" [title]="'community.page.news'">
          </ds-comcol-page-content>
        </header>
          <ds-dso-edit-menu></ds-dso-edit-menu>
          </div>
  
          <!-- Browse-By Links -->
      
          <ds-themed-comcol-page-browse-by [id]="parentContext.id" [contentType]="parentContext.type"></ds-themed-comcol-page-browse-by>
        </ng-container></ng-container>
      </div>
  
      <div class="col-md-9">
  
      <section class="comcol-page-browse-section">
        <ds-alphabet *ngIf="isAuthorBrowse" (letterSelected)="onLetterSelected($event)"></ds-alphabet>

      <div class="browse-by-metadata w-100">

      <ds-themed-browse-by *ngIf="!(loading$ | async)" class="col-xs-12 w-100"
        title="{{'browse.title' | translate:
        {
          collection: dsoNameService.getName((parent$ | async)?.payload),
          field: 'browse.metadata.' + browseId | translate,
          startsWith: (startsWith)? ('browse.startsWith' | translate: { startsWith: '&quot;' + startsWith + '&quot;' }) : '',
          value: (value)? '&quot;' + value + '&quot;': ''
        } }}"
        parentname="{{dsoNameService.getName((parent$ | async)?.payload)}}"
        [objects$]="(items$ !== undefined)? items$ : browseEntries$"
        [paginationConfig]="(currentPagination$ |async)"
        [sortConfig]="(currentSort$ |async)"
        [type]="startsWithType"
        [startsWithOptions]="startsWithOptions"
        (prev)="goPrev()"
        (next)="goNext()">
      </ds-themed-browse-by>

      <ds-themed-loading *ngIf="loading$ | async" message="{{'loading.browse-by-page' | translate}}"></ds-themed-loading>
    </div>
    </section>
    <ng-container *ngVar="(parent$ | async) as parent">
      <ng-container *ngIf="parent?.payload as parentContext">
    <footer *ngIf="parentContext.copyrightText" class="border-top my-5 pt-4">
      <div >
  
        <!-- Copyright -->
        <ds-comcol-page-content [content]="parentContext.copyrightText" [hasInnerHtml]="true">
        </ds-comcol-page-content>
      </div>
    </footer>
  </ng-container>
  </ng-container>
  </div>
  </div>
  </div>
  