import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ds-alphabet',
  template: `
    <div class="alphabets-wrapper">
      <div class="alphabet-container d-flex justify-content-center mb-2">
        <button *ngFor="let letter of englishAlphabet" 
                class="btn btn-outline-primary mx-1"
                (click)="letterSelected.emit(letter)">
          {{ letter }}
        </button>
      </div>
      <div class="alphabet-container d-flex justify-content-center mb-4">
        <button *ngFor="let letter of greekAlphabet" 
                class="btn btn-outline-primary mx-1"
                (click)="letterSelected.emit(letter)">
          {{ letter }}
        </button>
      </div>
    </div>
  `,
  styles: [`
    .alphabet-container {
      width: 100%;
      white-space: nowrap;
    }
    .alphabet-container button {
      min-width: 20px; 
      height: 30px;
      padding: 1px;
      font-size: 0.9rem;
      margin: 1px !important;
    }
  `]
})
export class AlphabetComponent {
  @Output() letterSelected = new EventEmitter<string>();
  
  englishAlphabet: string[] = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
  greekAlphabet: string[] = ['(', '-', ...Array.from('ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩ')];
}
