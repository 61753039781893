<div class="navbar-nav d-flex flex-row align-items-center" *ngIf="moreThanOneLanguage">
  <a href="javascript:void(0);" 
     class="nav-link px-2"
     [class.active]="'el' === translate.currentLang"
     (click)="useLang('el')"
     role="button"
     tabindex="0">
    EL
  </a>
  <span class="px-1">/</span>
  <a href="javascript:void(0);" 
     class="nav-link px-2"
     [class.active]="'en' === translate.currentLang"
     (click)="useLang('en')"
     role="button"
     tabindex="0">
    EN
  </a>
</div>
